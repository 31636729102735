/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { checkDeviceIsIos, isIOS, isIos1, isIphoneOrIpad, isWebView } from '../Utilities';

const DevToolsBlocker = ({ children }) => {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;

    // Function to detect DevTools
    const detectDevTools = () => {
      const threshold = 160;

      const widthDiff = window.outerWidth - window.innerWidth;
      const heightDiff = window.outerHeight - window.innerHeight;

      // Check if debugger statement is significantly delayed
      const checkDebugger = () => {
        const start = Date.now();
        debugger; // Detect DevTools
        return Date.now() - start > 100;
      };

      return widthDiff > threshold || heightDiff > threshold || checkDebugger();
    };

    // Function to handle DevTools detection on load and monitor changes
    const handleDevTools = () => {
      if (isWebView) return;
      if (checkDeviceIsIos()) return;

      // Check DevTools state initially and on environment changes
      const checkDevToolsState = () => {
        if (detectDevTools()) {
          setIsDevToolsOpen(true);
        }
      };

      // Monitor environment changes (resize, focus, etc.)
      const intervalId = setInterval(checkDevToolsState, 500); // Re-check periodically
      window.addEventListener('resize', checkDevToolsState);
      window.addEventListener('focus', checkDevToolsState);

      // Prevent right-click context menu
      const preventContextMenu = (e) => e.preventDefault();
      document.addEventListener('contextmenu', preventContextMenu);

      // Prevent DevTools keyboard shortcuts
      const preventDevToolsShortcuts = (e) => {
        const isCtrlShiftKey = e.ctrlKey && e.shiftKey;
        const isF12 = e.key === 'F12';

        if ((isCtrlShiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C')) || isF12) {
          e.preventDefault();
          setIsDevToolsOpen(true);
        }
      };
      document.addEventListener('keydown', preventDevToolsShortcuts);

      // Cleanup
      return () => {
        clearInterval(intervalId);
        window.removeEventListener('resize', checkDevToolsState);
        window.removeEventListener('focus', checkDevToolsState);
        document.removeEventListener('contextmenu', preventContextMenu);
        document.removeEventListener('keydown', preventDevToolsShortcuts);
      };
    };

    // Initialize detection
    const cleanup = handleDevTools();

    // Cleanup on unmount
    return cleanup;
  }, []);

  useEffect(() => {
    if (isDevToolsOpen) {
      window.location.href = 'about:blank';
    }
  }, [isDevToolsOpen]);

  // Block rendering if DevTools is detected
  if (isDevToolsOpen) {
    return (
      <div
        className='fixed inset-0 bg-black'
        style={{
          zIndex: 9999,
          width: '100vw',
          height: '100vh',
        }}
      />
    );
  }

  // Render children if no DevTools detected
  return <>{children}</>;
};

export default DevToolsBlocker;
